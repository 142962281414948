export const WIDGET_TABSWITCHER_STR = "widget_tabswitcher";
export const WIDGET_GROUPWITHSWITCH_STR = "widget_groupwithswitch";
export const WIDGET_NOTFOLDABLE_STR = "widget_notfoldable";
export const WIDGET_EXPANDEDBYDEFAULT_STR = "widget_expandedbydefault";
export const WIDGET_SIMPLE_GROUP_STRING = "widget_simplegroup";
export const WIDGET_HORIZONTALLAYOUT_STR = "widget_horizontallayout";
export const WIDGET_NOWIDGET_STR = "widget_nowidget";
// export const WIDGET_SWITCH_STR = "widget_switch";
export const WIDGET_CONTENT_SCROLLER = "content_scroller";
export const WIDGET_TRACK_SELECTOR = "widget_trackselector";
export const WIDGET_SETTINGS_STRING = "widget_settings";
export const WIDGET_3D_VIEW = "widget_3dview";
export const WIDGET_RADIO = "widget_radio";
export const WIDGET_SCENE_NAME_STRING = "widget_scenename";
export const WIDGET_IMAGEBUTTON = "widget_imagebutton";

// special user-ids
export const SYNC_ALL_TRACKS_ID = "_syncAllTracks";
export const COUNT_ID = "_count";
export const SOLO_ID = "_solo";
export const MUTE_ID = "_mute";
export const LOCK_ID = "_lock";
export const ONOFF_TOGGLE_ID = "_onoff";