export const VERSION_STR = "1.0.12-spacemusic";

// colors
export const COMPONENT_DEFAULT_COLOR = "#5C7080";
export const GRAY1 = "#5C7080";
export const LIGHT_GRAY5 = "#F5F8FA";
export const DARK_GRAY1 = "#182026";

// some text (only necessary in https context)
export const SSL_INFO_TEXT = "Connecting to unsecure websocket from secure context. You may need to adjust your browser settings.";
export const SSL_INFO_TEXT_FIREFOX = "To allow this please set: 'network.websocket.allowInsecureFromHTTPS' in 'about:config' to 'true'.";

export const DEFAULT_RCP_PORT = 10000;
export const SSL_PORT = 443;
export const HTTP_PORT = 80;

export const DEFAULT_PRECISION = 2;